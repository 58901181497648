<script>
    import octicons from "@primer/octicons";

    export let type = "info";

    function getIcon() {
        if (type === "warn") {
            return octicons.alert;
        }

        return octicons.info;
    }
</script>

<style>
    .alert {
        padding: 15px;
        margin-bottom: var(--area-padding);
        background: #dddddd;
        color: #000000;
        border-radius: 3px;
        display: flex;
        align-items: center;
    }

    .alert--warn {
        background: #ffc107;
        color: #000000;
        fill: #000000;
    }

    .alert__text {
        margin-left: 15px;
    }
</style>

<div class="alert alert--{type}">
    {@html getIcon().toSVG({ width: 25 })}
    <span class="alert__text">
        <slot />
    </span>
</div>
