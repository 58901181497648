<script>
	import { Router, Route } from "svelte-routing";
	import Header from "./components/Header.svelte";
	import ConfirmPartcipant from "./routes/ConfirmParticipant.svelte";

	export let url = "";
</script>

<style>
	:root {
		--color-primary: #c5001e;
		--color-secondary: #00c51e;
		--area-padding: 25px;
		--header-height: 65px;
		--floating-button-height: 65px;
	}

	:global(body) {
		font-family: "Lato", sans-serif;
	}

	.route-container {
		margin-top: var(--header-height);
		margin-bottom: var(--floating-button-height);
	}
</style>

<Router {url}>
	<Header />
	<div class="route-container">
		<Route path="confirm/:uuid" component={ConfirmPartcipant} />
	</div>
</Router>
