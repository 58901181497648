<script>
    import { onMount } from "svelte";

    import Loader from "../components/Loader.svelte";
    import Alert from "../components/Alert.svelte";
    import FloatingButton from "../components/FloatingButton.svelte";

    export let uuid;

    let data = {};
    let hints = [];

    let loading = true;

    async function handleSaveClick() {
        for (const hint of hints) {
            if (!hint.id && !hint.description) {
                continue;
            }

            await fetch("/api/hint", {
                method: hint.id ? "PATCH" : "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...hint, participant_id: data.id }),
            });
        }

        await refresh();
    }

    async function refresh() {
        loading = true;

        const response = await fetch(`/api/participant/${btoa(uuid)}`);

        hints = new Array(5).fill(null).map(() => ({
            id: null,
            description: "",
        }));

        data = await response.json();

        for (const [i, hint] of data.hints.entries()) {
            hints[i] = hint;
        }

        loading = false;
    }

    onMount(async () => {
        refresh();
    });
</script>

<style>
    .confirm-participant {
        padding: var(--area-padding);
    }

    .details__title {
        font-weight: bold;
        color: #333333;
    }

    .details__value {
        margin: 0.5em 0 1.5em 0;
        color: #666666;
        line-height: 1.5;
    }

    .details__list {
        color: #666666;
        line-height: 1.5;
        margin: 0.5em 0 1.5em 0;
    }

    .hint {
        width: 100%;
        margin: 10px 0;
        padding: 10px;
        border: 1px solid #cccccc;
        border-radius: 3px;
        box-sizing: border-box;
    }
</style>

{#if loading}
    <Loader />
{:else}
    <div class="confirm-participant">
        <Alert type="warn">
            If you're not
            <strong>{data.name}</strong>, let Dom know.
        </Alert>
        <div class="details">
            <div class="details__title">Occasion Name</div>
            <div class="details__value">{data.occasion.name}</div>
            <div class="details__title">Date</div>
            <div class="details__value">
                {new Date(data.occasion.date).toLocaleDateString()}
            </div>
            <div class="details__title">Gift Value</div>
            <div class="details__value">${data.occasion.price.toFixed(2)}</div>
            <div class="details__title">You're giving a gift to</div>
            <div class="details__value">{data.receiver.name}</div>
            <div class="details__title">
                Listed below are the hints
                {data.receiver.name}
                has provided
            </div>
            {#if data.receiver.hints.length === 0}
                <div class="details__value">
                    Unfortunately,
                    {data.receiver.name}
                    is yet to provide any hints
                </div>
            {:else}
                <ul class="details__list">
                    {#each data.receiver.hints as hint}
                        <li>{hint.description}</li>
                    {/each}
                </ul>
            {/if}

            <div class="details__title">Your Hints</div>
            <div class="details__value">
                Provide your gift hints here. Whoever got you will be able to
                view these.
            </div>
            {#each hints as hint, i}
                <input
                    type="text"
                    class="hint"
                    placeholder="Hint #{i + 1}"
                    bind:value={hint.description} />
            {/each}
        </div>
    </div>
    <FloatingButton on:click={handleSaveClick} />
{/if}
