<script>
    import octicons from "@primer/octicons";

    export let icon;
</script>

<style>
    .floating-button {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: var(--floating-button-height);
        background: #222222;
        padding: 15px;
        box-sizing: border-box;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }

    button {
        width: 100%;
        height: 100%;
        background: #169c32;
        color: #ffffff;
        font-weight: bold;
        border: none;
        outline: none;
        transition: all 0.5s;
    }

    button:hover {
        background: #0d6920;
    }
</style>

<div class="floating-button">
    {#if icon}
        {@html octicons[icon].toSVG({ width: 16 })}
    {/if}
    <button type="button" on:click>Save</button>
</div>
